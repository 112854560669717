import { useState } from 'react';
import { Flex, Spacer, Box, Text } from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';
import Link from 'next/link';
import { Header } from './Header';
import { SkipLink } from './SkipLink';
import { CloseIcon } from './Icons/CloseIcon';
import { HamburgerIcon } from './Icons/HamburgerIcon';
import { AgainAgainIconRect } from './Icons/AgainAgainIconRect';
import { SideBarNav } from './SideBarNav';

type Props = {
  hasMenu?: boolean;
};

export function PrimaryNav({ hasMenu = true }: Props) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const styles = useMultiStyleConfig('PrimaryNav', {});

  return (
    <>
      <Header>
        <SkipLink />
        <Flex>
          {isSidebarOpen && <CloseIcon onClick={toggleSidebar} aria-label="Open Menu" />}
          {!isSidebarOpen && <HamburgerIcon onClick={toggleSidebar} aria-label="Open Menu" />}
          <Box
            marginLeft="24px"
            paddingTop="var(--chakra-sizes-1)"
            paddingBottom="var(--chakra-sizes-1)"
          >
            <Flex position="absolute" top="5px" left="45%" right="45%">
              <AgainAgainIconRect height="27px" margin="auto" />
            </Flex>
          </Box>
        </Flex>
      </Header>
      <SideBarNav isOpen={isSidebarOpen} hasMenu={hasMenu} />
    </>
  );
}
