import { Container } from '@chakra-ui/react';
import { Content } from 'components/Content';
import ErrorAccount from 'components/ErrorAccount';
import Head from 'next/head';
import { Main } from 'components/Main';
import { PrimaryNav } from 'components/PrimaryNav';
import ProfileCreate from './ProfileCreate';
import { SideBarNav } from 'components/SideBarNav';
import { useUserContext } from './UserContext';
import { useVendorsContext } from './VendorsContext';
import { useRouter } from 'next/router';

type Props = {
  children: JSX.Element | JSX.Element[];
  hasMenu?: boolean;
  pageTitle: string;
};

const VendorLayout = ({ children, hasMenu = true, pageTitle }: Props) => {
  const { hasError } = useVendorsContext();
  if (hasError) hasMenu = false;

  const router = useRouter();

  if (hasError) {
    router.push('/member/profile');
  }

  return (
    <>
      <Head>
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap"
          rel="stylesheet"
        />
        <title>{pageTitle} | Again Again Vendor Portal</title>
      </Head>
      {<ProfileCreate />}
      <PrimaryNav hasMenu={hasMenu} />
      <Main>
        <Container id="vendor-layout" maxW="800px" p={0}>
          <Content>{hasError ? <ErrorAccount /> : children}</Content>
        </Container>
      </Main>
    </>
  );
};

export default VendorLayout;
